import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Container, Row, Col } from "react-bootstrap"
import "../components/Blog/blog.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faExternalLink} from "@fortawesome/pro-solid-svg-icons"

const PageTemplate = (props) => {
  const { data, location } = props;
  const pageLocation = location.pathname;
  
  const document = data.contentfulPage;
  console.log(pageLocation)

  // Render \n to <br />
  const Text = ({ children }) => (
    <p style={{ whiteSpace: 'pre-line' }}>{children}</p>
  )


  const options = {
    renderNode: {
       // Render \n to <br />
       [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,

      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: { fields },
        },
      }) => (
        <div
          dangerouslySetInnerHTML={{
            __html: `<img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="fluid" style="margin: 20px 0 20px 0; max-width: 100%" />`,
          }}
        />
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const pageUrl = node.data.target.fields.slug["en-US"]
        console.log(node.data.target.fields.slug["en-US"])
        return (
          <>
          { pageLocation.includes('espanol') ? (
            <Link to={`/summer-swim-lessons/${pageUrl}`} className="underline">
            {children}{' '} <FontAwesomeIcon
        icon={faChevronRight}
        aria-hidden="true"
        size="xs"
        />
          </Link>
         )
        :
        (
          <Link to={node.data.target.fields.slug["en-US"]} className="underline">
            {children}{' '} <FontAwesomeIcon icon={faChevronRight} aria-hidden="true" size="sm" />
          </Link>
        )
      }
      
        </>
      ) 
      },

      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const fields = node.data.target.fields.imageLink
        const imgLnk = node.data.target.fields.url
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: `<a href="${imgLnk["en-US"]}"><img src="${fields["en-US"].fields.file["en-US"].url}" alt="${fields.title}"  /></a>`,
            }}
          />
        )
      },
    },
  }

  return (
    <Layout pageTitle={document.pageTitle}>
      <Container
        style={{
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
        
      >
        <Row style={{
            justifyContent: 'center'
        }} >
          <Col>
            <div
              style={{
                marginTop: "2rem",
                // overflow: 'hidden'
              }}
            >
                <p>{document.updatedAt}</p>
              <h1 className="document-title">{document.pageTitle}</h1>
              <div style={{
                  borderBottom: '4px solid #7FAA74',
                  marginBottom: '2rem',
                  
              }} />
              <p className="blog-text">
              {documentToReactComponents(
                document.childContentfulPagePageContentRichTextNode.json,
                options
              )}
              </p>
            </div>
          </Col>
        </Row>

      </Container>
    </Layout>
  )
}

export const getBlogdocument = graphql`
query ContentfulPageQuery($slug: String) {
  contentfulPage(slug: { eq: $slug }) {
    slug
    pageTitle
    childContentfulPagePageContentRichTextNode {
      json
    }
  }
}
`

export default PageTemplate
